export const questions = [
  {
    id: 1,
    question: 'Do I have to be present?',
    answer: `It is not necessary to be at home during the cleaning session. However; if you feel comfortable staying, we will be more than happy to see you there.`,
  },
  {
    id: 2,
    question: 'What do I need to provide?',
    answer: `It depends on what type of cleaning you chose. With basic regular clean/weekly/biweekly service, we will ask you to have basic stuff on hand, such as: windex, clorox, soft scrub, floor cleaner, paper towel also broom or vacuum and mop. With deep clean service, we do provide all cleaning products- except broom/mop and vacuum.`,
  },
  {
    id: 3,
    question: 'Will I expect to see the same cleaning person?',
    answer: `Yes. As long as we have a weekly or biweekly service with you.`,
  },
  {
    id: 4,
    question: 'Are my valuables safe?',
    answer: `If you have any special valuables or irreplaceable items, please put them away or instruct us to not touch them.`,
  },
  {
    id: 5,
    question: 'How do I pay?',
    answer: `We do accept: cash, check, Zelle*payments`,
  },
  {
    id: 6,
    question: 'Is tip included to a total amount?',
    answer: `No, it is not. It is optional. However, it is expected to be 15-20% of total amount (preferable in cash).`,
  },
  {
    id: 7,
    question: 'Do you offer a house cleaning gift certificates?',
    answer: `Yes. You can purchase a cleaning gift certificates for almost any occasion.`,
  },
  {
    id: 8,
    question: 'Do you provide eco-friendly cleaning services?',
    answer: `Yes, we do. However; for a deep clean services, regular cleaning products are recommended.`,
  },
  {
    id: 9,
    question: 'What should I do with my pets during the house cleaning?',
    answer: `We like pets, but please tell us if you have any and provide any information regarding your pets that would be helpful for us to know, such as if they are friendly and they are not afraid of strangers.`,
  },
  {
    id: 10,
    question: 'What if I am not fully satisfied with the service?',
    answer: `Your satisfaction is very important to us. Feel free to call us within 24h.`,
  },
  {
    id: 11,
    question: 'Cancellation policy',
    answer: `We need 24h notice about cancellation. If you cancel with less than 24 hour notice, you will be charged $70.`,
  },
];
