import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import Container from 'components/Container';
import ContactBox from 'components/ContactBox';
import HappyClients from 'components/HappyClients';
import PageTitle from 'components/PageTitle';

import Sider from 'content/Faq/Sider';
import Questions from 'content/Faq/Questions';

const Faq = () => (
  <Layout>
    <SEO title="FAQ" />

    <Container>
      <PageTitle>Your question, our answer</PageTitle>
      
      <Row>
        <Col lg={4}>
          <Sider />
        </Col>
        <Col lg={8}>
          <Questions />
        </Col>
      </Row>
    </Container>

    <HappyClients />
    <ContactBox />
  </Layout>
);

export default Faq;
