import React, { Component } from 'react';

import FileArrowDown from 'images/arrow-down.svg';

import { questions } from './content';
import { Wrapper, QuestionWrapper, Question, Answer, Arrow} from './styled';

class Questions extends Component {
  state = {
    openQuestionId: null,
  };

  isQuestionOpen = id => this.state.openQuestionId === id;

  handleQuestionOpen = id => {
    this.setState({
      openQuestionId: id,
    });
  };

  render() {
    return (
      <Wrapper>
        {questions.map(({ id, question, answer }) => (
          <QuestionWrapper
            key={id}
            open={this.isQuestionOpen(id)}
            onClick={() => this.handleQuestionOpen(id)}
          >
            <Question>{question}</Question>
            <Answer>{answer}</Answer>

            <Arrow open={this.isQuestionOpen(id)}>
              <img src={FileArrowDown} alt="" />
            </Arrow>
          </QuestionWrapper>
        ))}
      </Wrapper>
    );
  }
}

export default Questions;
