import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'themes';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 110px 0 140px;
  text-align: center;

  ${media.md`
    padding: 90px 0 112px;
  `}

  ${media.sm`
    padding: 70px 0;
  `}
`;

export const Title = styled.div`
  color: #474c57;
  font-size: 32px;
  line-height: 52px;
  margin-bottom: 24px;

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
    padding: 0 80px;
  `}
`;

export const Subtitle = styled.div`
  color: #798190;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 75px;

  ${media.sm`
    padding: 0 50px;
    margin-bottom: 50px;
  `}
`;

export const Button = styled(Link)`
  position: relative;
  display: inline-block;
  padding: 12px 50px;
  border-radius: 30.5px;
  background-color: #75a605;
  box-shadow: 0 4px 8px -2px rgba(78, 112, 0, 0.41);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.img`
  position: absolute;
  left: -20px;
  top: -22px;
`;

export const Photo = styled.div`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(350px, -50%);

    ${media.md`
      display: none;
    `}
  }
`;
