import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import FileContactIcon from 'images/contact-page.svg';
import { Wrapper, Title, Subtitle, Button, Icon, Photo } from './styled';

const ContactBox = () => (
  <Wrapper>
    <Title>Need cleaning? Have a question?</Title>
    <Subtitle>Contact us directly or fill out the form</Subtitle>

    <Button to="/contact">
      <Icon src={FileContactIcon} />
      Contact page
    </Button>

    <StaticQuery
      query={graphql`
        query {
          contactImage: file(
            relativePath: { eq: "contact-section-photo.png" }
          ) {
            childImageSharp {
              fixed(width: 645, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <Photo>
          <Img fixed={data.contactImage.childImageSharp.fixed} />
        </Photo>
      )}
    />
  </Wrapper>
);

export default ContactBox;
