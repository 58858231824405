import React from 'react';
import { isTablet } from 'themes';
import { Wrapper, ContactBox, Button } from './styled';

const Sider = () => (
  <Wrapper>
    {isTablet ? (
      <p>
        Below you can find most popular questions asked by our customers. These
        topics will give you big picture of rules that we work along with.
      </p>
    ) : (
      <p>
        On the right side, you can find most popular questions asked by our
        customers. These topics will give you big picture of rules that we work
        along with.
      </p>
    )}

    <ContactBox>
      <p>
        Didn’t find needed information? You can still contact us directly and
        ask.
      </p>
      <Button to="/contact">Contact Page</Button>
    </ContactBox>
  </Wrapper>
);

export default Sider;
