import styled from 'styled-components';
import { media } from 'themes';

const PageTitle = styled.h1`
  color: #474c57;
  font-size: 32px;
  line-height: 40px;
  padding: 122px 0 56px;

  ${media.md`
    padding-top: 76px;
    padding-bottom: 32px;
  `}

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
    padding: 20px 0 16px 0;
  `}
`;

export default PageTitle;
