import React, { Component } from 'react';
import Slider from 'react-slick';
import { Row, Col } from 'styled-bootstrap-grid';

import FileHeartIcon from 'images/happy-clients-icon.svg';
import Container from 'components/Container';

import Slide from './components/Slide';
import { referals } from './content';
import { Wrapper, Circle, Title, Content, HeartIcon } from './styled';

class HappyClients extends Component {
  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'dots',
    arrows: false,
  };

  goNext = () => {
    this.slider.slickNext();
  }

  goPrevious = () => {
    this.slider.slickPrev();
  }

  render() {
    return (
      <Wrapper topOffset={this.props.topOffset}>
        <Content>
          <Title>
            Happy clients!

            <HeartIcon src={FileHeartIcon} alt="" />
          </Title>
  
          <Container>
            <Row>
              <Col lg={8} lgOffset={2}>
                <Slider {...this.settings} ref={slider => (this.slider = slider)}>
                  {referals.map(({ name, location, content, link }) => (
                    <Slide
                      key={name}
                      name={name}
                      location={location}
                      content={content}
                      link={link}
                      goNext={this.goNext}
                      goPrevious={this.goPrevious}
                    />
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </Content>
  
        <Circle />
      </Wrapper>
    );
  }
}

HappyClients.defaultProps = {
  topOffset: false,
};

export default HappyClients;
