import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.div`
  padding-bottom: 140px;

  ${media.md`
    padding-bottom: 50px;
  `}
`;

export const QuestionWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 24px 70px 0 32px;

  ${({ open }) => open && `
    background-color: rgba(244, 251, 227, 0.5);
    padding-bottom: 24px;
    margin-top: 24px;
    
    ${Answer} {
      display: block;
    }
  `}

  ${media.md`
    padding-left: 0;
    padding-right: 0;

    ${({ open }) => open && `
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;
    `}
  `}

  ${media.sm`
    padding-left: 16px;
    padding-right: 40px;

    ${({ open }) => open && `
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
    `}
  `}
`;

export const Question = styled.div`
  color: #75a605;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  
  &:hover {
    cursor: pointer;
  }
`;

export const Answer = styled.div`
  display: none;
  color: #474c57;
  font-size: 14px;
  line-height: 28px;
  margin-top: 25px;
`;

export const Arrow = styled.div`
  position: absolute;
  top: 28px;
  right: 45px;

  ${({ open }) => open && `
    transform: scaleY(-1);
  `}

  ${media.sm`
    right: 16px;
  `}
`;
