import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'themes';

export const Wrapper = styled.div`
  color: #5a5f6a;
  font-size: 14px;
  line-height: 28px;
`;

export const Button = styled(Link)`
  display: inline-block;
  margin-top: 16px;
  border-radius: 30.5px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px -2px rgba(78, 112, 0, 0.41);
  color: #75a605;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding: 8px 22px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContactBox = styled.div`
  border-radius: 10px;
  background-color: #75a605;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 24px 32px;
  margin: 40px 0;

  ${media.md`
    ${({ hide }) => hide && `
      display: none;
    `}
  `}

  ${media.sm`
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
  `}
`;
