import styled from 'styled-components';
import { media } from 'themes';

export const Wrapper = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;

  ${media.sm`
    padding: 0 16px;
  `}
`;

export const Content = styled.div`
  color: #5a5f6a;
  font-size: 18px;
  line-height: 34px;
  text-align: center;

  ${media.sm`
    font-size: 14px;
    line-height: 28px;
  `}
`;

export const Person = styled.div`
  color: #798190;
  margin: 34px 100px;
  min-width: 150px;

  ${media.sm`
    margin: 34px 30px;
    min-width: 170px;
  `}
`;

export const More = styled.a`
  display: inline-flex;
  align-items: flex-end;
  color: #75a605;
  text-decoration: none;

  img {
    width: 56px;
    margin-left: 7px;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviousArrow = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

export const NextArrow = styled(PreviousArrow)`
  transform: scaleX(-1);
`;
