import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import { media } from 'themes';

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  background-color: #f3f8ff;
  padding: 150px 0 100px;
  min-height: 682px;
  overflow: hidden;

  ${({ topOffset }) =>
    topOffset &&
    `
      margin-top: -45px;
    `}

  ${media.md`
    padding-bottom: 60px;
  `}

  ${media.sm`
    padding-top: 110px;
    padding-bottom: 40px;
    min-height: 0;
  `}

  && {
    .dots {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 70px;

      ${media.sm`
        margin-top: 30px;
      `}

      li {
        display: flex;
        align-items: center;
        justify-content: center;

        & + li {
          margin-left: 12px;
        }
      }

      button {
        height: 4px;
        width: 4px;
        background-color: #e4e4e4;
        border: 0;
        padding: 0;
        border-radius: 100%;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        transition: width 200ms, height 200ms, background-color 200ms;

        &:focus {
          outline: 0;
        }
      }

      .slick-active {
        button {
          height: 6px;
          width: 6px;
          background-color: #75a605;
        }
      }
    }
  }
`;

export const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1406px;
  height: 1406px;
  background-color: #ffffff;
  border-radius: 100%;

  ${media.md`
    width: 846px;
    height: 846px;
  `}

  ${media.sm`
    height: 536px;
    width: 536px;
    top: calc(50% + 20px);
  `}
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
`;

export const Title = styled.h2`
  position: relative;
  display: inline-block;
  color: #474c57;
  font-size: 32px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 48px;

  ${media.sm`
    font-size: 24px;
    line-height: 32px;
  `}
`;

export const HeartIcon = styled.img`
  position: absolute;
  top: -40px;
  right: -70px;
`;
