export const referals = [
  {
    name: `Dan R.`,
    location: `Brooklyn, NY`,
    content: `I am updating my review of Joanna Cleaning Service because I have been working with Joanna and her team for over 7 years and I am not only satisfied, but I wouldn't dream of using another service to clean my apartment. Joanna and her team clean our place about once per month and I still look forward to coming home on cleaning day. EVERYTHING is completed to our exact request. They are trustworthy, honest, reasonably priced and very responsible. I have never had a sub-par experience with this service and as a result, I have referred her to several friends. Joanna rocks. Use her service without reservation. You will definitely be impressed with the results. I know I am- even after over 85 cleanings.`,
    link: 'https://www.yelp.com/user_details?userid=-xhdyuKoFf8yhR4Mt8UsSw',
  },
  {
    name: `Mark B.`,
    location: `Fort Greene, NY`,
    content: `My wife and I have been using Joanna's Cleaning Service for more than 8 years and she runs a very professional, responsible, and thorough cleaning service.  She is always responsive to any issues we've had over the years and we trust in their quality of work.  
    Previous review: Joanna has been cleaning for us weekly, for about two years now. She is very professional, maintains high standards, is always reliable, and most importantly, is an excellent cleaner. She clearly really cares about her clients and takes great pride in her work. I recommend her and her service without any reservations.`,
    link: 'https://www.yelp.com/user_details?userid=1VOAJcAsbMnajX4zGfXvTA',
  },
  {
    name: `Winona K.`,
    location: `Brooklyn, NY`,
    content: `I wish I could give six stars!  I contacted Joanna Cleaning for a deep clean of my apartment to tidy up after an extensive renovation of our place by building management when we were away (drilling through drywall, tons of workers in our place..basically - havoc!).   Wow!  They did an amazing job.  Not only did they take care of the debris from the work - but our place was absolutely spotless.  Last week - two months after their visit - I went to get something off a high shelf in the bathroom.  The area - normally a bit grimy - was completely spotless.   While I tend to give the place a pretty thorough clean weekly - I will definitely hire them again.  My mom has a cleaning service and even she was impressed.  They are the best - and extremely reasonable for the quality of service.`,
    link: 'https://www.yelp.com/user_details?userid=kb0HFzVqele3KMu-HgQDgg',
  },
  {
    name: `Cara N.`,
    location: `New York, NY`,
    content: `I just had a deep cleaning by Claudia and Alina and couldn't be happier! This was my first deep clean with a professional service and needless to say, my apartment needed it. My cleaners arrived early, asked if I had any special requests (which I did - and all of which were honored!), worked efficiently, and left my apartment spotless and organized. They were friendly and professional throughout (though I left the apartment for most of the cleaning) and also made sure to check my satisfaction before they left, which I really appreciated. Thank you Claudia and Alina for your very hard work! I will definitely use this service again.`,
    link: 'https://www.yelp.com/user_details?userid=95jAOBzUX_ka6ULYE20WoQ',
  },
  {
    name: `Fiona H.`,
    location: `Manhattan, NY`,
    content: `I'm updating my review 7ish years later to say that I'm still using Joanna's service, and still as happy as ever. I particularly appreciate how flexible Joanna is about scheduling and payment (texting and Venmo is my new method), and that she has been so great about never forgetting my address and preferences. That's particularly impressive since this is the fourth apartment I've lived in since I started using her service, and sometimes I'll go months between cleanings if things are complicated in my life. Then again, as soon as I'm ready to start regular cleanings again, Joanna's on it and ready to make my life so much easier (and cleaner!).`,
    link: 'https://www.yelp.com/user_details?userid=dqHloypj6ITe-s094j2N8g',
  },
  {
    name: `Samara L.`,
    location: `Brooklyn, NY`,
    content: `Just used their service for the first time and I'm so impressed! I had Klaudia who did such an incredible job. So thorough! I'm very picky and usually just prefer to clean myself because no one does it like I do, but Klaudia did! She even organized all the little nicnacs on my makeup and bedside tables which was such a nice touch. I will definitely be using her services again! Highly recommend!`,
    link: 'https://www.yelp.com/user_details?userid=dWDxkhm0_T5aEmZw2f_T6g',
  },
  {
    name: `Victoria L.`,
    location: `NY, NY`,
    content: `This is the first cleaning service I've used where I have no qualms whatsoever. I wasn't able to let them in because of work, so I wasn't sure what to expect when I got home, but I've never been happier with a cleaning. Everything was spotless, carefully arranged, and the instructions I left were followed to a t. I will definitely be using this service again, as should you.`,
    link: 'https://www.yelp.com/user_details?userid=3bSflbmmd9t1LwQQC1U2EA',
  },
];
