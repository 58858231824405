import React from 'react';
import FileArrowLeft from 'images/slider-left.svg';
import FileYelp from 'images/yelp-logo-color.svg';
import {
  Wrapper,
  Content,
  Person,
  More,
  ArrowWrapper,
  NextArrow,
  PreviousArrow,
} from './styled';

const Slide = ({ content, name, location, goPrevious, goNext, link }) => (
  <Wrapper>
    <Content>"{content}"</Content>

    <ArrowWrapper>
      <PreviousArrow onClick={goPrevious}>
        <img src={FileArrowLeft} alt="" />
      </PreviousArrow>

      <Person>
        {name}
        <br />
        {location}
      </Person>

      <NextArrow onClick={goNext}>
        <img src={FileArrowLeft} alt="" />
      </NextArrow>
    </ArrowWrapper>
    <More href={link} target="_blank" rel="noopener noreferer">
      Read full story at <img src={FileYelp} alt="Yelp" />
    </More>
  </Wrapper>
);

export default Slide;
